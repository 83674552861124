.stream__title {
  text-align: center;
  margin-top: 20px;
  font-family: cursive;
  text-shadow: 2px 2px 10px;
}

.stream__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 80px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 780px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .stream__cart__user {

    @media only screen and (max-width: 1200px) {
      width: -webkit-fill-available;
      height: 350px;
    }
  }

  .stream__cart {
    width: 294px;
    height: auto;
    box-shadow: 1px 1px 10px rgb(185, 180, 180);
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0 10px 0;

    @media only screen and (max-width: 1200px) {
      width: -webkit-fill-available;
      height: auto;
    }

    .stream__cart__delete__icon {
      position: absolute;
      top: 10px;
      right: 10px;

      .stream__delete__icon {
        color: red;
        cursor: pointer;
      }
    }

    .stream__cart__body {
      width: 70%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stream__cart__body__price {
        font-size: 15px;
        margin: 8px 0 0 0;
      }

      .stream__cart__body__information {
        margin: 10px 0;
        font-size: 12px;
        font-weight: bold;

        .stream_cart_info_text {
          margin-top: 3px;
        }
      }

      .stream__cart__body__input {
        width: 90%;
        padding: 7px 10px;
        margin: 10px 0 0 0;
        border-radius: 8px;
        border: 1px solid #0f3a7b;
        outline: none;
        cursor: grab;
      }

      .stream__cart__body__input::placeholder {
        text-align: center;
      }

      .stream__all__input {
        cursor: auto;
      }
    }

    .stream__cart__image__ramce {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      border: 3px solid #0f3a7b;
      cursor: pointer;
      position: relative;
      background-color: #d2dbe2;  

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.stream__modal__body {
  padding: 0px;

  .stream__modal__form__text {
    margin-top: 20px;
    width: 100%;
    color: white;
  }

  .stream__modal__form__group {
    margin-top: 20px;
    width: 100%;
    .stream__modal__input {
      padding: 10px;
      width: -webkit-fill-available;
      background: white;
      border: 0px;
      border-radius: 3px;
    }
  }

  .stream__modal__button__box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: end;

    .stream__modal__button {
      padding: 5px 20px;
      display: flex;
      border-radius: 3px;
      background: white;
      border-radius: 3px;
      border: 0px;
      cursor: pointer;
      outline: none;
      color: #0f3a7b;
      font-size: 15px;
      font-weight: bold;

      ._icon {
        margin-left: 5px;
        color: #0f3a7b;
      }
    }

    .stream__modal__button__left {
      margin-right: 10px;
    }

    .stream__modal__button__left {
      margin-right: 10px;
    }
  }
}

.react-responsive-modal-closeButton {
  margin-top: 5px;
}

#my-modal-title {
  color: aliceblue;
  font-family: cursive;
}

.stream__page__not {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stream__button {
  margin-top: 15px;
}

.stream__telegram__icon {
  margin-left: 10px;
  color: #0088cc;
}

.stream__cart__body__error_value {
  font-size: 12px;
  text-align: center;
  color: red;
  text-shadow: 1px 1px 10px black;
}

.stream__cart__body__delivery_is_paid {
  margin-top: 10px;
  color: #0f3a7b;
}

.stream__cart__body__telegram_link {
  padding: 10px 0;
  color: red;
  cursor: pointer;
}

.stream__card__create_form_row {
  width: 100%;
}

.stream__cart__body__title__loading__parent {
    width: 80%;
    min-height: 10px;
    background-color: #d2dbe2;
    border-radius: 7px;
    position: relative;
}

.stream__cart__body__price__loading__parent {
    width: 90%;
    min-height: 20px;
    background-color: #d2dbe2;
    border-radius: 7px;
    position: relative;
    margin: 5px;
}

.stream__cart__body__input__loading__parent {
    width: 100%;
    min-height: 30px;
    background-color: #d2dbe2;
    border-radius: 7px;
    position: relative;
    margin: 5px;
}

.stream_search_parent {
  text-align: center;

  .stream_input_search {
    width: 80%;
    padding: 10px 7px;
    border: 1px solid #0f3a7b;
    border-radius: 3px;
  }
}

.stream__region__confirm {
  font-size: 14px;
  display: flex;
  margin-top: 15px;
  align-items: center;
  color: #0f3a7b;
  font-weight: bold;
}

.stream__region__chekbox {
  margin-right: 10px;
}

.stream_delivery_control {
  font-size: 12px;
  color: red;
}

.stream_agent_informasion {
  background-color: #0088cc;
  padding: 10px;
  color: #d2dbe2;
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;

  .stream_agent_informasion_text_aligin {
    color: yellow;
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
  }
}

.category_navbar {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  padding: 20px 0;
  margin-top: 10px;

  .category_button {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #0f3a7b;
    border: 2px #0f3a7b solid;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
    min-width: max-content;
  }
}

.stream__cart__sklat {
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;

  .operator_icon {
    margin-left: 5px;
  }
}